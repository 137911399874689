<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH VACUUM DEGREASERS'</span>
        <h1 class="slide-top-second">Servicio de limpieza con ultrasonidos con <br class="d-none d-lg-block">disolventes
            clorados AIII o hidrocarburos AIII</h1>
        <hr class="introHr slide-left-right">
        <h3 class="slide-top-third">Lavado y desengrase exhaustivo de piezas metálicas en cámara al vacío, con
            disolventes clorados AIII o hidrocarburos AIII, mediante la tecnología 'Solvacs', para procesos de
            producción de piezas con restos principalmente orgánicos.</h3>
    </div>
</header>
<section class="container-fluid disolventes no-gutters-own">
    <div class="container disolventes__container mx-auto">
        <div class="row">
            <nav class="col-12 breadcrumb-lg-intro">
                <ol class="breadcrumb-lg-intro__ol">
                    <li class="breadcrumb-item"><a routerLink="/"
                            title="servicio de limpieza de piezas sonoclean">Volver a la home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/servicios-de-limpieza"
                            title="todos nuestros servicios de limpieza de piezas"><span>todos</span></a></li>
                    <li class="breadcrumb-item active" aria-current="page">base disolventes AIII</li>
                </ol>
            </nav>
        </div>
        <div class="row disolventes__container__row">
            <div class="col-12 col-lg-6 disolventes__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/altos-niveles-limpieza-exterior-interior.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body disolventes__container__row__col__text">
                        <h2><span class="light">Alta limpieza para producciones elevadas </span><br>con hidrocarburos
                            AIII o alcoholes modificados AIII</h2>
                        <hr>
                        <p>Los procesos de limpieza de base disolventes (Alcoholes Modificados AIII o Hidrocarburos
                            AIII) con los que trabajan nuestras máquinas tipo
                            'Solvacs', son especialmente indicados para
                            la eliminación de restos en su mayoría orgánicos, como, <span class="medium">aceites,
                                huellas, polvos
                                o ceras</span>.</p>
                        <p>Asimismo, los cambios de presión del líquido a alta temperatura, la geometría antipartículas de la cámara al vacío, la rotación de la cesta en la misma, el efecto de los ultrasonidos y el vapor de disolvente, hacen que los procesos de lavado de las 'Solvacs' sean altamente eficaces, no sólo para la eliminación de restos orgánicos, sino también para gran parte de los restos inorgánicos, derivados de los procesos de producción de piezas.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 disolventes__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/maquinas-tecnologia-puntera-alemana-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body disolventes__container__row__col__text">
                        <h2><span class="light">Infraestructura, amplia experiencia</span><br> y
                            tecnología 'Solvacs', puntera en el sector</h2>
                        <hr>
                        <p>En SonoClean contamos con la tecnología y el 'know how' de nuestro equipo técnico para resolver
                            los casos más exigentes de limpieza, desengrase y secado de piezas.</p>
                        <p>Para ofrecer el servicio con base disolventes AIII en producciones medianas y elevadas
                            de piezas con residuos tipo: aceites, grasas, polvos o ceras, trabajamos con
                            maquinaria Sonowat y del Grupo Surface Alliance (Hemo), de los más
                            especializados, punteros y con experiencia de Europa en el sector de los ultrasonidos y sus aplicaciones de
                            lavado de piezas.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="container-fluid disolventes no-gutters-own bg-ecru">
    <div class="container disolventes__container mx-auto">
        <div class="row disolventes__container__row">
            <div class="col-12 disolventes__container__row__col">
                <h3><span class="light">Esquema de proceso y detalle</span><br> de nuestros equipos 'Solvacs' con
                    disolventes AIII
                </h3>
                <hr>
                <div class="disolventes__container__row__col__processGrid">
                    <figure>
                        <img src="./assets/img/icons/solvacs/seleccion-programa-hemo-sonowat-blue.svg"
                            alt="seleccion programa limpieza particulas" width="100%" height="auto">
                        <figcaption>Selección programa</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/carga-automatica-hemo-sonowat-blue.svg"
                            alt="pista de carga automática de las piezas en cesta" width="100%" height="auto">
                        <figcaption>Carga automática</figcaption>
                    </figure>

                    <figure class="flexSAPointsItem flexSAInside hideElement">
                        <img src="./assets/img/icons/solvacs/camara-antiparticulas-hemo-sonowat-blue.svg"
                            alt="piezas en camara con geometria antiparticulas" width="100%" height="auto">
                        <figcaption>Cámara antipartículas</figcaption>
                    </figure>

                    <figure class="flexSAPointsItem flexSAInside hideElement">
                        <img src="./assets/img/icons/solvacs/efecto-vacio-hemo-sonowat-blue.svg"
                            alt="efecto vacio en camara de trabajo" width="100%" height="auto">
                        <figcaption>Efecto vacío</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/llenado-disolvente-hemo-sonowat-blue.svg"
                            alt="llenado de disolvente aiii" width="100%" height="auto">
                        <figcaption>Llenado disolvente</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/rotacion-de-la-cesta-hemo-sonowat-blue.svg"
                            alt="rotacion de la cesta en la camara de trabajo" width="100%" height="auto">
                        <figcaption>Rotación cesta</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/ultrasonidos-hemo-sonowat-blue.svg"
                            alt="ultrasonidos en camara de trabajo" width="100%" height="auto">
                        <figcaption>Ultrasonidos cámara</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/efecto-cavitacion-hemo-sonowat-blue.svg"
                            alt="efecto cavitacion" width="100%" height="auto">
                        <figcaption>Efecto Cavitación</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/filtracion-producto-hemo-sonowat-blue.svg"
                            alt="filtracion del producto" width="100%" height="auto">
                        <figcaption>Filtración producto</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/vapor-disolvente-hemo-sonowat-blue.svg"
                            alt="vapor de disolvente" width="100%" height="auto">
                        <figcaption>Vapor disolvente</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/eliminacion-particulas-hemo-sonowat-blue.svg"
                            alt="eliminacion de particulas de suciedad en las piezas" width="100%" height="auto">
                        <figcaption>Eliminación partículas</figcaption>
                    </figure>


                    <figure>
                        <img src="./assets/img/icons/solvacs/secado-al-vacio-hemo-sonowat-blue.svg"
                            alt="secado al vacio" width="100%" height="auto">
                        <figcaption>Secado al vacío</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/descarga-automatica-hemo-sonowat-blue.svg"
                            alt="descarga automatica" width="100%" height="auto">
                        <figcaption>Descarga automática</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/solvacs/control-particulas-hemo-sonowat-blue.svg"
                            alt="control de particulas" width="100%" height="auto">
                        <figcaption>Control partículas</figcaption>
                    </figure>
                </div>
            </div>


            <figure class="col-lg-5 ml-lg-auto disolventes__container__row__col-machineImage mt-large">
                <div class="flexGroupCamaras">

                    <figure class="flexGroupCamarasItem flexGroupCamarasInside">
                        <img src="./assets/img/icons/solvacs/camaras/desengrase-una-camara-disolvente-hemo-sonowat.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasInsideItem">Una cámara de disolvente
                        </figcaption>
                    </figure>

                    <figure class="flexGroupCamarasItem flexGroupCamarasInside">
                        <img src="./assets/img/icons/solvacs/camaras/desengrase-dos-camaras-disolvente-hemo-sonowat.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasInsideItem">Dos cámaras de disolvente
                        </figcaption>
                    </figure>


                    <figure class="flexGroupCamarasItem flexGroupCamarasInside">
                        <img src="./assets/img/icons/solvacs/camaras/desengrase-tres-camaras-disolvente-hemo-sonowat.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasInsideItem">Tres cámaras de disolvente
                        </figcaption>
                    </figure>

                </div>

                <p class="description">Trabajan indistintamente con hidrocarburos AIII o con alcoholes modificados AIII en una, dos o tres
                    cámaras con geometría antipartículas y selladas sin recovecos. Cada cámara cuenta con un filtro
                    previo. Las 'Solvacs', además, cuentan con un filtro común posterior de malla de nylon, con
                    capacidad para retener partículas y micropartículas hasta 200 micras. Trabajan completamente al
                    vacío y en un proceso 100% respuetuoso con el medioambiente.</p>

            </figure>
            <figure class="col-12 col-lg-7 ml-lg-auto disolventes__container__row__col-machineImage mt-large">
                <img src="./assets/img/maquinas-limpieza-ultrasonidos/pista-carga-descarga-Solvacs.jpg"
                    alt="maquina de limpieza de piezas con disolventes aiii sonoclean" width="100%" height="auto">
                <figcaption>Detalle de equipo Solvacs para limpieza y desengrase con disolventes AIII</figcaption>
            </figure>

        </div>
    </div>
</section>
<section class="container-fluid disolventes no-gutters-own bBottom-ecru">
    <div class="container disolventes__container mx-auto">
        <div class="row disolventes__container__row">

            <div class="col-12 col-lg-6 disolventes__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/info-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body disolventes__container__row__col__text">
                        <h3 class="green">¿En qué casos es el más indicado?</h3>
                        <hr>
                        <p>Como menciomamos en el anterior punto es un proceso especialmente indicado para procesos de
                            producción intermedios en los que las piezas acumulan suciedades como <span
                                class="medium">aceites,
                                huellas, polvos, ceras</span> u otro tipo de impurezas del tamaño de partículas o
                            micropartículas que precisas ser eliminadas para seguir la cadena de producción en sectores
                            como
                            la
                            estmpación, el decoletaje o el mecanizado de piezas.</p>
                            <h4 class="common__subtitle">Materiales</h4>
                            <ul class="common__list">
                                <li>Hierro</li>
                                <li>Aluminio</li>
                                <li>Latón</li>
                                <li>Cobre</li>
                                <li>Acero inoxidable</li>
                                <li>Níquel</li>
                                <li>Zinc</li>
                                <li>Plomo</li>
                                <li>Resinas rígidas</li>
                                <li>Plásticos rígidos</li>
                            </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 disolventes__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integra-servicio-limpieza-sonoclean-2.svg"
                        alt="logistica integral del servicio">
                    <div class="media-body disolventes__container__row__col__text">
                        <h3 class="green">Opciones logísticas del serivcio</h3>
                        <hr>

                        <ul>
                            <li>Lavado de piezas a granel</li>
                            <li>Lavado de piezas colocadas en cesta</li>
                            <li>Lavado de piezas colocadas en utillaje</li>
                            <li>Protecciones anicorrosivas y antioxidantes</li>
                            <li>Recuento de piezas pre y post lavado</li>
                        </ul>
                    </div>
                </div>
                <div class="media mt-medium">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integral-servicio/control-de-particulas.svg"
                        alt="controles y certificaciones de lavado">
                    <div class="media-body disolventes__container__row__col__text">

                        <h3 class="green">Controles y Certificaciones de lavado</h3>
                        <hr>
                        <ul>
                            <li>Controles de granulometría</li>
                            <li>Controles de tensión superficial</li>
                            <li>Controles de gravimetría (partículas)</li>
                            <li>Certificaciones de lavado y documentaciones de procesos</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-call-to-action></app-call-to-action>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
        <div class="row generic-section__container__row">
            <div class="col-12 generic-section__container__row__col">
                <h2 class="w-100"><span class="light">Vea todos los servicios </span><br>de limpieza y desengrase de
                    piezas SonoClean</h2>
                <hr>
            </div>
        </div>
    </div>
</section>
<app-services-links></app-services-links>