<section class="container-fluid no-gutters-own clients">
    <div class="container clients__container">
        <h2><span class="light">Una larga trayectoria </span><br>a base de clientes satisfechos</h2>
        <hr>
        <div class="clients__container__logos-grid">

            <img src="./assets/img/clientes/cliente-sonowat-graphenea.jpg" alt="cliente sonowat graphenea"
                title="cliente sonowat graphenea" />

            <img src="./assets/img/clientes/cliente-sonowat-samson.jpg" alt="cliente sonowat Samson"
                title="cliente sonowat Samson" />

            <img src="./assets/img/clientes/cliente-sonowat-bmw.jpg" alt="cliente sonowat BMW"
                title="cliente sonowat Equipo limpieza ultrasonidos BMW sector automoción" />

            <img src="./assets/img/clientes/cliente-sonowat-axilone.jpg" alt="cliente sonowat Axilone"
                title="cliente sonowat Axilone" />

            <img src="./assets/img/clientes/cliente-sonowat-ti-automotive.jpg" alt="cliente sonowat TI Automotive"
                title="cliente sonowat TI Automotive" />

            <img src="./assets/img/clientes/cliente-sonowat-teknia.jpg" alt="cliente sonowat Teknia"
                title="cliente sonowat Teknia" />

            <img src="./assets/img/clientes/cliente-sonowat-talgo.jpg" alt="cliente sonowat Talgo"
                title="cliente sonowat Talgo" />

            <img src="./assets/img/clientes/cliente-sonowat-some.jpg" alt="cliente sonowat Some"
                title="cliente sonowat Some" />

            <img src="./assets/img/clientes/cliente-sonowat-sincrotro-alba.jpg" alt="cliente sonowat Sincrotró Alba"
                title="cliente sonowat Sincrotró Alba" />

            <img src="./assets/img/clientes/cliente-sonowat-simon.jpg" alt="cliente sonowat Simon"
                title="cliente sonowat Simon" />

            <img src="./assets/img/clientes/cliente-sonowat-tmb.jpg" alt="cliente sonowat TMB"
                title="cliente sonowat TMB" />

            <img src="./assets/img/clientes/cliente-sonowat-tesem.jpg" alt="cliente sonowat Tesem"
                title="cliente sonowat Tesem" />

            <img src="./assets/img/clientes/cliente-sonowat-seat.jpg" alt="cliente sonowat SEAT"
                title="cliente sonowat SEAT" />

            <img src="./assets/img/clientes/cliente-sonowat-roca.jpg" alt="cliente sonowat Roca"
                title="cliente sonowat Roca equipos de limpieza con ultrasonidos para grifería" />

            <img src="./assets/img/clientes/cliente-sonowat-plating-decor.jpg" alt="cliente sonowat Plating Decor"
                title="cliente sonowat Plating Decor" />


            <img src="./assets/img/clientes/cliente-sonowat-phibo.jpg" alt="cliente sonowat Phibo"
                title="cliente sonowat equipo limpieza ultrasonidos limpieza exhaustiva implantes dentales Phibo"
                />


            <img src="./assets/img/clientes/cliente-sonowat-mmm.jpg" alt="cliente sonowat MMM"
                title="cliente sonowat equipo de desengrase con alcoholes modificados AIII MMM" />

            <img src="./assets/img/clientes/cliente-sonowat-microdent-implant-system.jpg"
                alt="cliente sonowat Microdent" title="cliente sonowat Microdent" />


            <img src="./assets/img/clientes/cliente-sonowat-mcm.jpg" alt="cliente sonowat MCM"
                title="cliente sonowat MCM" />

            <img src="./assets/img/clientes/cliente-sonowat-inkator.jpg" alt="cliente sonowat Inkator"
                title="cliente sonowat limpieza piezas con geometria compleja" />


            <img src="./assets/img/clientes/cliente-sonowat-iberia.jpg" alt="cliente sonowat Iberia"
                title="cliente sonowat maquinas limpieza ultrasonidos sector aeronautica Iberia" />

            <img src="./assets/img/clientes/cliente-sonowat-hutchinson.jpg" alt="cliente sonowat Hitchinson"
                title="cliente sonowat Hitchinson" />

            <img src="./assets/img/clientes/cliente-sonowat-grifols.jpg" alt="cliente sonowat Grifols"
                title="cliente sonowat Grifols" />


            <img src="./assets/img/clientes/cliente-sonowat-genebre.jpg" alt="cliente sonowat Genebre"
                title="cliente sonowat Genebre" />


            <img src="./assets/img/clientes/cliente-sonowat-endesa.jpg" alt="cliente sonowat Endesa"
                title="cliente sonowat cuba ultrasonidos Endesa" />

            <img src="./assets/img/clientes/cliente-sonowat-vilardell-purti.jpg" alt="cliente sonowat Vilardell Purtí"
                title="cliente sonowat Vilardell Purtí" />


            <img src="./assets/img/clientes/cliente-sonowat-delphi-technologies.jpg" alt="cliente sonowat Delphi"
                title="cliente sonowat Delphi Technologies maquina limpieza ultrasonidos multietapa cabinada"
                />

            <img src="./assets/img/clientes/cliente-sonowat-danone.jpg" alt="cliente sonowat Danone"
                title="cliente sonowat Equipo limpieza ultrasonidos sector alimentario" />


            <img src="./assets/img/clientes/cliente-sonowat-bioner.jpg" alt="cliente sonowat Bioner"
                title="cliente sonowat máquina de limpieza poarticulas" />



            <img src="./assets/img/clientes/cliente-sonowat-amcor.jpg" alt="cliente sonowat Amcor"
                title="cliente sonowat equipo limpieza hidrocarburos AIII Amcor" />


        </div>
    </div>
</section>