import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logistica-integral',
  templateUrl: './logistica-integral.component.html',
  styleUrls: ['./logistica-integral.component.scss']
})
export class LogisticaIntegralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
