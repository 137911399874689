<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH CLEANERS & DEGREASERS'</span>
        <h1 class="slide-top-second">Servicios periféricos y complementarios <br class="d-none d-lg-block">a los de limpieza de piezas</h1>
        <hr class="introHr slide-left-right">
        <h3 class="slide-top-third">Secado al vacío o con aire caliente, controles de tensión superficial, análisis gravimétricos, de partículas y protecciones antioxidantes</h3>
        <!-- <p class="slide-top-third"> Altas prestaciones en lavado en piezas metálicas en cámara, con base acuosa y detergentes, con posterior enjuague, secado, protección antioxidante, mediante la tecnología 'PowerJet', para procesos de producción de piezas con restos principalmente inorgánicos.</p> -->
    </div>
</header>
<section class="container-fluid servicios-perifericos no-gutters-own">
    <div class="container servicios-perifericos__container mx-auto">
        <div class="row">
            <nav class="col-12 breadcrumb-lg-intro">
                <ol class="breadcrumb-lg-intro__ol">
                    <li class="breadcrumb-item"><a routerLink="/"
                            title="servicio de limpieza de piezas sonoclean">Volver a la home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/servicios-de-limpieza"
                            title="todos nuestros servicios de limpieza de piezas"><span>todos</span></a></li>
                    <li class="breadcrumb-item active" aria-current="page">servicios periféricos</li>
                </ol>
            </nav>
        </div>
        <div class="row servicios-perifericos__container__row">
            <div class="col-12 col-lg-8 servicios-perifericos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                    src="./assets/img/icons/logistica-integral-servicio/control-de-particulas.svg" alt="analisis de particulas">
                    <div class="media-body servicios-perifericos__container__row__col__text">
                        <h2>Análisis de partículas</h2>
                        <hr>
                        <p>Todas nuestras máquinas trabajan con una cámara para disolventes con los geometría antipartículas y un filtro previo en la misma. Disponemos de Solvacs configuradas para trabajar en combinación con 1, 2 y hasta 3 cáma- ras de trabajo de disolvente, que cuentan con procesos de filtración comu- nes en la máquina y específicos para cada una de ellas, y mediante filtros de partículas y micropartículas posteriores de nylon hasta 200 micras, para llegar a niveles extremadamente elevados de eliminación de residuo en las piezas. Sus programas de filtración, y sus técnicas de unión de tuberías con a prueba de escapes de aire a presión son algunas de las claves de la calidad de lavado que ofrecen.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 servicios-perifericos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                    src="./assets/img/icons/logistica-integral-servicio/analisis-gravimetrico.svg" alt="analisis gravimetrico">
                    <div class="media-body servicios-perifericos__container__row__col__text">
                        <h2>Análisis gravimétricos</h2>
                        <hr>
                        <p>Después de los procesos de lavado, desengrase y secado ‘Sonoclean’ realizamos, además de los análisis de granulometría o control de partículas a las piezas, análisis de tensión superficial a las mismas, que consisten en una serie de análisis químicos desde distintos puntos de vista, con los que medimos cuantitativamente cuánto resido se ha desprendido de las piezas, midiendo su peso antes y después del proceso.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 servicios-perifericos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                    src="./assets/img/icons/logistica-integral-servicio/tension-superficial.svg" alt="controles de tension superficial">
                    <div class="media-body servicios-perifericos__container__row__col__text">
                        <h2>Tensión superficial</h2>
                        <hr>
                        <p>Con ella medimos el grado de adhesión de las moléculas de aceites y ta- ladrina en la pieza y por lo tanto su grado de desengrase. A más tensión superficial mayor desengrase. Al finalizar el proceso y medir el grado de desengrase de las piezas usaremos el método de tintas específicas para la medición de tensión superficial. El grado de absorción y adherencia de la tinta sobre la pieza nos indicará su nivel de tensión superficial.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 servicios-perifericos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                    src="./assets/img/icons/logistica-integral-servicio/proteccion-antioxidante-y-secado-al-vacio.svg" alt="analisis gravimetrico">
                    <div class="media-body servicios-perifericos__container__row__col__text">
                        <h2>Protecciones antioxidantes + secado al vacío</h2>
                        <hr>
                        <p>Otros procesos complementarios que pueden suelen ser requeridos en el tratamiento superficial de piezas y para los que también pueden ser pro- gramados los equipos ‘Solvacs’, son las protecciones antioxidantes para las piezas del tipo ‘Antikorit’ en el depoósito anterior al del secado al va- cío. En anticorrosivo será administrado mediante dosificador automático. Para cerrar el círculo y garantizar los resultados, se van a proporcionar las herramientas para realizar a los análisis de tensión superficial y control de partículas y microparticulas.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-call-to-action></app-call-to-action>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
        <div class="row generic-section__container__row">
            <div class="col-12 generic-section__container__row__col">
                <h2 class="w-100"><span class="light">Vea todos los servicios </span><br>de limpieza y desengrase de
                    piezas SonoClean</h2>
                <hr>
            </div>
        </div>
    </div>
</section>
<app-services-links></app-services-links>
