<header class="bg-intro-medium">
    <div class="container no-gutters">
        <h1 class="slide-top-first">Contacte con SonoClean</h1>
        <hr class="introHr slide-left-right">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb slide-top-second">
                <li class="breadcrumb-item"><a routerLink="/" title="servicio de limpieza de piezas">Volver a la
                        home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contacto</li>
            </ol>
        </nav>
    </div>
</header>
<section class="container-fluid contacto no-gutters-own bg-ecru">
    <div class="container contacto__container mx-auto">
        <div class="row contacto__container__row">
            <div class="col-12 col-lg-6 contacto__container__row__col-infoService">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</section>