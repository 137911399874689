<!-- Section - Services Links -->
<section class="container-fluid section no-gutters-own">
    <div class="container mx-auto">
        <div class="row section__servicios-home-row">
            <article class="col-12 section__servicios-home-row__article">
                <figure routerLink="/servicios-de-limpieza/disolventes-aiii" title="servicio de limpieza y desengrase de piezas con disolventes aiii" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/desengrase-piezas-laton-sector-estampacion.jpg"
                        alt="desengrase piezas laton sector estampacion" width="100%" height="auto" />
                    <figcaption>
                        <h2>Hidrocarburos AIII o <br>Alcoholes modificados AIII</h2>
                        <hr>
                        <p class="d-none d-lg-block">Residuos <span class="green bold">70%</span> orgánicos <span class="green bold">30%</span> inorgánicos</p>
                        <ul class="d-none d-lg-block">
                            <li>Aceites</li>
                            <li>Lubricantes</li>
                            <li>Siliconas</li>
                            <li>Ceras</li>
                        </ul>
                    </figcaption>
                </figure>

                <figure routerLink="/servicios-de-limpieza/base-acuosa-camara" title="servicio de limpieza de piezas con base acuosa en camara" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/limpieza-desengrase-aceites-piezas-geometria-compleja.jpg"
                        alt="limpieza y desengrase de particulas grasas aceites" width="100%" height="auto">
                        <figcaption>
                            <h2>Base acuosa con turbulencia en cámara</h2>
                            <hr>
                            <p class="d-none d-lg-block">Residuos <span class="green bold">70%</span> inorgánicos <span class="green bold">30%</span> orgánicos</p>
                            <ul class="d-none d-lg-block">
                                <li>Grasas</li>
                                <li>Aceites</li>
                                <li>Ceras</li>
                                <li>Petróleos</li>
                            </ul>
                        </figcaption>
                </figure>

                <figure routerLink="/servicios-de-limpieza/hibrida-disolvententes-aiii-detergentes" title="servicio de limpieza de piezas con base disolventes aiii y acuosa en camara" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/limpieza-desengrase-aceites-taladrina-pizas-mecanizadas.jpg"
                        alt="desengrase piezas mecanizadas con taladrina, ferricha y sales" width="100%" height="auto" />
                        <figcaption>
                            <h2>Proceso híbrido disloventes AIII + base acuosa</h2>
                            <hr>
                            <p class="d-none d-lg-block"><span class="green bold">'Spot Free'</span> Orgánicos e Inorgánicos</p>
                            <ul class="d-none d-lg-block">
                                <li>Sales</li>
                                <li>Taladrinas</li>
                                <li>Aceites</li>
                                <li>Petróleos</li>
                            </ul>
                        </figcaption>
                </figure>

                <figure routerLink="/servicios-de-limpieza/ultrasonidos"
                title="servicio de limpieza con ultrasonidos por inmersión en baño con agua y detergentes" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-despues.jpg"
                        alt="limpieza con ultrasonidos por inmersion en baño con agua y detergentes" width="100%" height="auto">
                        <figcaption>
                            <h2>Limpieza con ultrasonidos<br> por inmersión</h2>
                            <hr>
                            <p class="d-none d-lg-block">Recuperación de piezas con restos<br> <span class="green bold">70%</span> inorgánicos <span class="green bold">30%</span> orgánicos</p>
                            <ul class="d-none d-lg-block">
                                <li>Grasas</li>
                                <li>Óxidos</li>
                                <li>Cal y sales</li>
                                <li>Carbonillas</li>
                            </ul>
                        </figcaption>
                </figure>

                <figure routerLink="/servicios-perifericos" title="servicios perifericos sonoclean" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/limpieza-ultrasonidos-particulas-sector-automocion.jpg"
                        alt="control de particulas post limpieza ultrasonidos sonoclean" width="100%" height="auto">
                        <figcaption>
                            <h2>Servicios periféricos</h2>
                            <hr>
                            <ul class="d-none d-lg-block section__servicios-home-row__article__item__ul">
                                <li>Secado al vacío</li>
                                <li>Protecciones antioxidantes</li>
                                <li>Anticorrosivos</li>
                                <li>Análisis gravimetrico</li>
                                <li>Análisis granulométrico</li>
                                <li>Análisis de tensión superficial</li>
                                <li>Certificaciones post lavado</li>
                            </ul>
                        </figcaption>
                </figure>

                <figure routerLink="/logistica-integral" title="logistica integral servicios de limpieza sonoclean" class="section__servicios-home-row__article__item">
                    <img src="./assets/img/aplicaciones-limpieza/servicios-perifericos-colocacion-y-recuento-de-piezas.jpg"
                        alt="servicios perifericos y complementarios sonoclean" width="100%" height="auto">
                        <figcaption>
                            <h2>Servicios complementarios<br> y logística integral</h2>
                            <hr>
                            <ul class="d-none d-lg-block">
                                <li>Colocación en cesta o útil</li>
                                <li>Recuento pre lavado</li>
                                <li>Recuento post lavado</li>
                                <li>Envasado al vacío</li>
                                <li>Envasado en bolsas VCI</li>
                                <li>Gestión del envío</li>
                            </ul>
                        </figcaption>
                </figure>

                

                

            </article>
        </div>
    </div>
</section>