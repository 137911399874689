<header class="header bg-intro-large no-gutters-own">
  <div class="container header__container no-gutters">
    <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH VACUUM DEGREASERS'</span>
    <h1 class="slide-top-second">Ofrecemos servicios de limpieza <br class="d-none d-lg-block">y desengrase
      de piezas con ultrasonidos</h1>
    <hr class="introHr slide-left-right">
    <h2 class="slide-top-third">Procesos integrales de lavado de altas prestaciones en cámara o por inmersión con base
      acuosa, disolventes AIII e híbridos
    </h2>
    <button class="button-white slide-left slide-top-fourth" routerLink="/servicios-de-limpieza">ver
      servicios</button>
  </div>
</header>

<section class="container no-gutters-own section mx-auto">
  <div class="row section__presentation-row">
    <article class="col-12 col-lg-5 section__presentation-row__box-text">
      <picture>
        <img src="./assets/img/icons/logistica-integral-servicio/hidrocarburos-aiii-alcoholes-modificados-aiii.svg"
          alt="sonoclean limpieza altas prestaciones" width="86px" height="86px" />
      </picture>
      <h2><span class="light">SonoClean<sup>&reg;</sup>, la nueva estructura dedicada </span><br>a servicios de limpieza
        con alta exigencia</h2>
      <hr />
      <p>Como empresa pionera en la fabricación de máquinas de limpieza de piezas con ultrasonidos, con más de 40 años
        de experiencia en el sector, nos hemos ido adaptando a los importantes cambios que se han ido sucediendo en la
        nueva industria, con producciones cada vez más diversas de piezas con geometrías más complejas, que exigen
        procesos de lavado y control cada vez más específicos. Con el fin de acercar la solución tecnológica más
        adecuada a cada aplicación o requisito que nos han exigido nuestros clientes, hemos decidido crear una nueva
        estructura dedicada en exclusiva a los servicios, que nos permite una más ágil y eficaz gestión de una parte de
        nuestro negocio en claro y exponencial crecimiento. Con ello queremos dar resupesta, desde exigencias de lavado
        muy específica con control de partículas, a grandes producciones, incluyendo la logística completa de las
        piezas.</p>
    </article>
    <div class="col-12 col-lg-5 offset-lg-1 section__presentation-row__box-slider align-self-center">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        </ol>
        <div class="carousel-inner section__presentation-row__box-slider__aplications">

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/desengrase-piezas-laton-sector-estampacion.jpg"
              alt="desengrase piezas laton sector estampacion" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/desengrase-piezas-laton-sector-estampacion-sonoclean.jpg"
              alt="desengrase piezas laton sector estampacion sonoclean" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100" src="./assets/img/aplicaciones-limpieza/limpieza-ultrasonidos-tubos-aluminio.jpg"
              alt="limpieza ultrasonidos tubos aluminio proceso disolventes aiii" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-despues.jpg"
              alt="limpieza ultrasonidos xxl" width="100%" height="auto">
          </div>

          <div class="carousel-item active section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-ultrasonidos-particulas-sector-automocion.jpg"
              alt="limpieza ultrasonidos-particulas sector automocion" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-desengrase-aceites-taladrina-pizas-mecanizadas.jpg"
              alt="limpieza y desengrase aceites taladrina pizas mecanizadas" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-ultrasonica-piezas-aluminio-sector-alimentario-utillaje.jpg"
              alt="limpieza ultrasonica piezas aluminio sector alimentario utillaje" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-desengrase-aceites-piezas-geometria-compleja.jpg"
              alt="limpieza y desengrase aceites piezas geometria compleja" width="100%" height="auto">
          </div>

          <div class="carousel-item section__presentation-row__box-slider__aplications__item">
            <img class="d-block w-100"
              src="./assets/img/aplicaciones-limpieza/limpieza-ultrasonidos-secto-automovil.jpg"
              alt="limpieza por ultrasonidos sector automovil" width="100%" height="auto">
          </div>

        </div>

        <a class="carousel-control-prev carousel-item section__presentation-row__box-slider__aplications__control-prev"
          href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
        <a class="carousel-control-next section__presentation-row__box-slider__aplications__control-next"
          href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</section>
<app-corporative-bar></app-corporative-bar>
<section class="container-fluid generic-section no-gutters-own">
  <div class="container generic-section__container mx-auto">
    <div class="row generic-section__container__row">
      <div class="col-12 generic-section__container__row__col">
        <h2 class="w-100"><span class="light">¿Cuáles son nuestros principales </span><br>servicios de limpieza y
          tratamiento de piezas?</h2>
        <hr>
      </div>
    </div>
  </div>
</section>
<app-services-links></app-services-links>
<app-call-to-action></app-call-to-action>