<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH CLEANERS & DEGREASERS'</span>
        <h1 class="slide-top-second">Servicios de limpieza de piezas <br class="d-none d-lg-block">con tecnología de ultrasonidos</h1>
            <hr class="introHr slide-left-right">
            <p class="slide-top-third">Lavado y desengrase con base disolvente y/o detergente de piezas a granel o colocadas en útil, <br class="d-none d-lg-block">con posterior secado, protección antioxidante, control de partículas y/o tensión superficial, colocación en cajas o bolsas VCI o envasadas al vacío, etc.</p>
    </div>
</header>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
      <div class="row generic-section__container__row">
        <div class="col-12 col-lg-9 w-100 generic-section__container__row__col">
          <h2 class="w-100"><span class="light">Elija la opción indicada para </span><br>el material o el tipo de suciedad de sus piezas</h2>
          <hr>
          <p>Si conoce el material, el tipo de suciedades que acumulan sus piezas, o tiene algún requisito específico puede seleccionar su opción deseada, si necesita más información, haga clic contacto o presupuesto y uno de nuestros técnicos le asesorará.</p>
        </div>
      </div>
    </div>
  </section>
<app-services-links></app-services-links>
<app-call-to-action></app-call-to-action>