<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH ULTRASONIC CLEANERS'</span>
        <h1 class="slide-top-second">Servicio de limpieza con ultrasonidos <br class="d-none d-lg-block">y turbulencia
            en cámara con base acuosa</h1>
        <hr class="introHr slide-left-right">
        <h3 class="slide-top-third">Altas prestaciones en lavado en piezas metálicas en cámara, con base acuosa y
            detergentes, mediante la tecnología 'PowerJet', para procesos de producción de piezas con restos
            principalmente inorgánicos.</h3>
        <!-- <p class="slide-top-third">Altas prestaciones en lavado en piezas metálicas en cámara, con base acuosa y detergentes, con posterior enjuague, secado, protección antioxidante, mediante la tecnología 'PowerJet', para procesos de producción de piezas con restos principalmente inorgánicos.</p> -->
    </div>
</header>
<section class="container-fluid base-acuosa no-gutters-own">
    <div class="container base-acuosa__container mx-auto">
        <div class="row">
            <nav class="col-12 breadcrumb-lg-intro">
                <ol class="breadcrumb-lg-intro__ol">
                    <li class="breadcrumb-item"><a routerLink="/"
                            title="servicio de limpieza de piezas sonoclean">Volver a la home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/servicios-de-limpieza"
                            title="todos nuestros servicios de limpieza de piezas"><span>todos</span></a></li>
                    <li class="breadcrumb-item active" aria-current="page">base acuosa</li>
                </ol>
            </nav>
        </div>
        <div class="row base-acuosa__container__row">
            <div class="col-12 col-lg-6 base-acuosa__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/altos-niveles-limpieza-exterior-interior.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body base-acuosa__container__row__col__text">
                        <h2><span class="light">Alta limpieza para producciones elevadas </span><br>con base acuosa en
                            cámara</h2>
                        <hr>
                        <p>Los procesos de limpieza con base acusoa con turbulencia en cámara con los que trabajan
                            nuestras máquinas tipo
                            'Power Jet', son especialmente indicados para
                            la eliminación de restos en su mayoría inorgánicos, como, <span class="medium">sales,
                                taladrinas
                                huellas o emulsiones</span>.</p>
                        <p>Asimismo, el efecto turbulencia del líquido a alta temperatura en la cámara, la rotación de
                            la cesta, los ultrasonidos y el vapor de agua, hacen que los procesos de lavado de las
                            'Power Jet' sean altamente eficaces, no sólo
                            para la eliminación de restos inorgánicos, sino también para gran parte de los restos
                            orgánicos, derivados de los procesos de producción de piezas.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 base-acuosa__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/maquinas-tecnologia-puntera-alemana-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body base-acuosa__container__row__col__text">
                        <h2><span class="light">Infraestructura, amplia experiencia</span><br> y
                            tecnología 'Power Jet', puntera en el sector</h2>
                        <hr>
                        <p>En SonoClean contamos con la tecnología y 'know how' de nuestro equipo técnico para resolver
                            los casos más complejos de limpieza, desengrase y secado de piezas.</p>
                        <p>Para ofrecer este servicio con base acuosa en producciones medianas y elevadas
                            de piezas con con residuos tipo sales, taladrinas, huellas o emulsiones, trabajamos con
                            maquinaria Sonowat y del Grupo Surface Alliance (LPW), de los más
                            especializados, punteros y con experiencia de Europa en el sector de los ultrasonidos y sus aplicaciones de
                            lavado de piezas.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="container-fluid base-acuosa no-gutters-own bg-ecru">
    <div class="container base-acuosa__container mx-auto">
        <div class="row base-acuosa__container__row">

            <div class="col-12 base-acuosa__container__row__col">
                <h3><span class="light">Esquema de proceso e imagen</span><br> de nuestros equipos 'Power Jet' con base
                    acuosa en cámara
                </h3>
                <hr>
                <div class="base-acuosa__container__row__col__processGrid">

                    <figure>
                        <img src="./assets/img/icons/powerjet/seleccion-programa-lpw-sonowat-blue.svg"
                            alt="seleccion programa limpieza particulas base acuosa" width="100%" height="auto">
                        <figcaption>Selección programa</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/carga-automatica-lpw-sonowat-blue.svg"
                            alt="pista de carga automática de las piezas en cesta" width="100%" height="auto">
                        <figcaption>Carga automática</figcaption>
                    </figure>

                    <figure class="flexSAPointsItem flexSAInside hideElement">
                        <img src="./assets/img/icons/powerjet/camara-detergente-lpw-sonowat-blue.svg"
                            alt="piezas en camara con geometria antiparticulas powerjet" width="100%" height="auto">
                        <figcaption>Cámara antipartículas</figcaption>
                    </figure>

                    <figure class="flexSAPointsItem flexSAInside hideElement">
                        <img src="./assets/img/icons/powerjet/llenado-automatico-agua-lpw-sonowat-blue.svg"
                            alt="llenado de agua en camara de trabajo" width="100%" height="auto">
                        <figcaption>Llenado agua</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/rotacion-de-la-cesta-lpw-sonowat-blue.svg"
                            alt="rotacion de la cesta para un lavado mas intensivo" width="100%" height="auto">
                        <figcaption>Rotación cesta</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/ultrasonidos-lpw-sonowat-blue.svg"
                            alt="ultrasonidos en camara" width="100%" height="auto">
                        <figcaption>Ultrasonidos cámara</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/efecto-turbulencia-lpw-sonowat-blue.svg"
                            alt="efecto turbulencia en camara de trabajo para desprender suciedades de las piezas"
                            width="100%" height="auto">
                        <figcaption>Efecto turbulencia</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/efecto-cavitacion-lpw-sonowat-blue.svg"
                            alt="efecto cavitacion" width="100%" height="auto">
                        <figcaption>Efecto Cavitación</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/filtracion-producto-lpw-sonowat-blue.svg"
                            alt="filtracion del agua" width="100%" height="auto">
                        <figcaption>Filtración agua</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/eliminacion-particulas-lpw-sonowat-blue.svg"
                            alt="eliminacion particulas" width="100%" height="auto">
                        <figcaption>Eliminación partículas</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/efecto-vacio-lpw-sonowat-blue.svg"
                            alt="efecto vacio en camara" width="100%" height="auto">
                        <figcaption>Vacío en cámara</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/secado-al-vacio-lpw-sonowat-blue.svg"
                            alt="secado al vacio" width="100%" height="auto">
                        <figcaption>Secado al vacío</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/descarga-automatica-lpw-sonowat-blue.svg"
                            alt="descarga automatica" width="100%" height="auto">
                        <figcaption>Descarga automática</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/powerjet/control-particulas-lpw-sonowat-blue.svg"
                            alt="control de particulas" width="100%" height="auto">
                        <figcaption>Control partículas</figcaption>
                    </figure>
                </div>
            </div>


            <figure class="col-lg-5 ml-lg-auto base-acuosa__container__row__col-machineImage mt-large">

                <div class="flexGroupCamarasPJ">

                    <figure class="flexGroupCamarasPJItem flexGroupCamarasPJInside">
                        <img src="./assets/img/icons/powerjet/camaras/una-camara-derergente-powerJet.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasPJInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasPJInsideItem">1 cámara</figcaption>
                    </figure>

                    <figure class="flexGroupCamarasPJItem flexGroupCamarasPJInside">
                        <img src="./assets/img/icons/powerjet/camaras/dos-camaras-derergente-powerJet.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasPJInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasPJInsideItem">2 cámaras</figcaption>
                    </figure>


                    <figure class="flexGroupCamarasPJItem flexGroupCamarasPJInside">
                        <img src="./assets/img/icons/powerjet/camaras/tres-camaras-derergente-powerJet.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasPJInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasPJInsideItem">3 cámaras</figcaption>
                    </figure>

                    <figure class="flexGroupCamarasPJItem flexGroupCamarasPJInside">
                        <img src="./assets/img/icons/powerjet/camaras/cuatro-camaras-derergente-powerJet.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasPJInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasPJInsideItem">4 cámaras</figcaption>
                    </figure>

                    <figure class="flexGroupCamarasPJItem flexGroupCamarasPJInside">
                        <img src="./assets/img/icons/powerjet/camaras/cinco-camaras-derergente-powerJet.svg"
                            alt="sonowat maquinas de limpieza y desengrase de alta produccion" width="100%"
                            class="flexGroupCamarasPJInsideItem">
                        <figcaption class="chamberNumber flexGroupCamarasPJInsideItem">5 cámaras</figcaption>
                    </figure>
                </div>

                <p class="description">El efecto turbulencia sumado al de los ultrasonidos, la cámara con geometría antipartículas y la
                    presión del líquido con su efecto turbulencia, controlados por precisión en cada movimiento de las Power Jet, ofrecen un lavado de altas prestaciones con base acuosa y detergente para la eliminación de partículas hasta 200 micras.</p>
            </figure>
            <figure class="col-12 col-lg-7 ml-lg-auto base-acuosa__container__row__col-machineImage mt-large">
                <img src="./assets/img/maquinas-limpieza-ultrasonidos/pista-carga-descarga-PowerJet.jpg"
                    alt="maquina de limpieza de piezas con disolventes aiii sonoclean" width="100%" height="auto">
                <figcaption>Detalle de equipo Power Jet para limpieza de partículas con base acuosa</figcaption>
            </figure>

        </div>
    </div>
</section>
<section class="container-fluid base-acuosa no-gutters-own bBottom-ecru">
    <div class="container base-acuosa__container mx-auto">
        <div class="row base-acuosa__container__row">

            <div class="col-12 col-lg-6 base-acuosa__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/info-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body base-acuosa__container__row__col__text">
                        <h3 class="green">¿En qué casos es el más indicado?</h3>
                        <hr>
                        <p>Como menciomamos en el anterior punto es un proceso especialmente indicado para procesos de
                            producción intermedios en los que las piezas acumulan suciedades como <span
                                class="medium">sales, taladrinas, huellas o emulsiones</span> u otro tipo de impurezas del tamaño de partículas o
                            micropartículas que precisas ser eliminadas para seguir la cadena de producción en sectores como el mecanizado de piezas con recovecos muy pequeños, el sector médico quirúrjico o el electrónico.</p>
                            <h4 class="common__subtitle">Materiales</h4>
                            <ul class="common__list">
                                <li>Hierro</li>
                                <li>Aluminio</li>
                                <li>Latón</li>
                                <li>Cobre</li>
                                <li>Acero inoxidable</li>
                                <li>Níquel</li>
                                <li>Zinc</li>
                                <li>Plomo</li>
                                <li>Resinas rígidas</li>
                                <li>Plásticos rígidos</li>
                            </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 base-acuosa__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integra-servicio-limpieza-sonoclean-2.svg"
                        alt="logistica integral del servicio">
                    <div class="media-body base-acuosa__container__row__col__text">
                        <h3 class="green">Opciones logísticas del serivcio</h3>
                        <hr>

                        <ul>
                            <li>Lavado de piezas a granel</li>
                            <li>Lavado de piezas colocadas en cesta</li>
                            <li>Lavado de piezas colocadas en utillaje</li>
                            <li>Protecciones anicorrosivas y antioxidantes</li>
                            <li>Recuento de piezas pre y post lavado</li>
                        </ul>
                    </div>
                </div>
                <div class="media mt-medium">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integral-servicio/control-de-particulas.svg"
                        alt="controles y certificaciones de lavado">
                    <div class="media-body base-acuosa__container__row__col__text">

                        <h3 class="green">Controles y Certificaciones de lavado</h3>
                        <hr>
                        <ul>
                            <li>Controles de granulometría</li>
                            <li>Controles de tensión superficial</li>
                            <li>Controles de gravimetría (partículas)</li>
                            <li>Certificaciones de lavado y documentaciones de procesos</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-call-to-action></app-call-to-action>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
        <div class="row generic-section__container__row">
            <div class="col-12 generic-section__container__row__col">
                <h2 class="w-100"><span class="light">Vea todos los servicios </span><br>de limpieza y desengrase de
                    piezas SonoClean</h2>
                <hr>
            </div>
        </div>
    </div>
</section>
<app-services-links></app-services-links>
