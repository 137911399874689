<header class="bg-intro-large">
  <div class="container no-gutters">
    <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH CLEANERS & DEGREASERS'</span>
    <h1 class="slide-top-second">Logística integral de los servicios <br class="d-none d-lg-block"></h1>
    <hr class="introHr slide-left-right">
    <h3 class="slide-top-third">
      Nos encargamos de la recogida, la colocación pre y post lavado, recuento de piezas, envasado al vacío o en bolsas
      VCI y de la gestión del envío.
    </h3>
  </div>
</header>
<section class="container-fluid logistica-integral no-gutters-own">
  <div class="container logistica-integral__container mx-auto">
      <div class="row">
          <nav class="col-12 breadcrumb-lg-intro">
              <ol class="breadcrumb-lg-intro__ol">
                  <li class="breadcrumb-item"><a routerLink="/"
                          title="servicio de limpieza de piezas sonoclean">Volver a la home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">logística del servicio</li>
              </ol>
          </nav>
      </div>
      <div class="row logistica-integral__container__row">
          <div class="col-12 col-lg-8 logistica-integral__container__row__col-infoService">
              <div class="media">
                  <img class="align-self-start paragraph__icon"
                  src="./assets/img/icons/logistica-integral-servicio/recuento-de-piezas.svg" alt="recuento de piezas">
                  <div class="media-body logistica-integral__container__row__col__text">
                      <h2>Recuento de piezas</h2>
                      <hr>
                      <p>Todas las lineas de lavado ‘SonoClean’ integran vásculas de conversión de peso a unidades de piezas. Este servicio complementario se ha convertido en imprescindible para nuestros clientes y para nuestro funcionamiento in- terno, para la limpieza de piezas a granel o colocadas, especialmente las de tamaño pequeño y mediano, donde las cantidades se pueden contar por mi- les o millones. Con nuestros dispositivos obtenemos cantidades exactas, que introducimos en el programa de gestión para generar informes parametriza- dos de gran utilidad para nuestros clientes y en el programa de la máquina, para optimizar cada vez más el proceso de lavado de nuestros clientes en todas sus piezas.</p>
                  </div>
              </div>
          </div>
          <div class="col-12 col-lg-8 logistica-integral__container__row__col-infoService">
              <div class="media">
                  <img class="align-self-start paragraph__icon"
                  src="./assets/img/icons/logistica-integral-servicio/colocacion-en-utillaje.svg" alt="colocacion en utillaje">
                  <div class="media-body logistica-integral__container__row__col__text">
                      <h2>Colocación de piezas (cesta o útil) durante el proceso</h2>
                      <hr>
                      <p>Aproximadamente el 40% de nuestros clientes requieren un servicio
                        de colocación de la pieza en útil, o bien para no ser rallada en el proceso de giro en la cámara de lavado, o bien para asegurar una posición que permita incidir en todas las partes internas y externas de las piezas.
                        El servicio integrado de pieza colocada en cesta con presionador o en útil es en muchos casos imprescindible para una limpieza de calidad.</p>
                  </div>
              </div>
          </div>
          <div class="col-12 col-lg-8 logistica-integral__container__row__col-infoService">
              <div class="media">
                  <img class="align-self-start paragraph__icon"
                  src="./assets/img/icons/logistica-integral-servicio/envasado-y-colocacion-piezas.svg" alt="envasado y colocacion de piezas">
                  <div class="media-body logistica-integral__container__row__col__text">
                      <h2>Envasado + colocación post proceso</h2>
                      <hr>
                      <p>En ‘SonoClean’ nos encargamos de cerrar el círculo de las piezas protegién- dolas en el embalaje reglamentario de la empresa contratante o bien en nuestro propio embalaje. Marcamos cada caja con la codificación corres- pondiente para parametrizar cada fase del proceso.</p>
                  </div>
              </div>
          </div>
          <div class="col-12 col-lg-8 logistica-integral__container__row__col-infoService">
              <div class="media">
                  <img class="align-self-start paragraph__icon"
                  src="./assets/img/icons/logistica-integral-servicio/envasado-bolsas-vci.svg" alt="envasado al vacio o en bolsas vci">
                  <div class="media-body logistica-integral__container__row__col__text">
                      <h2>Envasado al vacío o en bolsas VCI</h2>
                      <hr>
                      <p>Es habitual encontrarse que la pieza requiere de un cuidado especial, suele ser habitual embalar las piezas por separado o a granel al vacío para la conservación de la protección antioxidante o simplemente para evitar que se oxiden por si solas. En ‘SonoClean’ disponemos de maquinaria periférica para ello.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<app-call-to-action></app-call-to-action>
<section class="container-fluid generic-section no-gutters-own">
  <div class="container generic-section__container mx-auto">
      <div class="row generic-section__container__row">
          <div class="col-12 generic-section__container__row__col">
              <h2 class="w-100"><span class="light">Vea todos los servicios </span><br>de limpieza y desengrase de
                  piezas SonoClean</h2>
              <hr>
          </div>
      </div>
  </div>
</section>
<app-services-links></app-services-links>