import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mapa-del-sitio',
  templateUrl: './mapa-del-sitio.component.html',
  styleUrls: ['./mapa-del-sitio.component.scss']
})
export class MapaDelSitioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
