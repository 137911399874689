<footer class="no-gutters-own footer">
    <div class="container footer__links">
        <div class="row">
            <div class="col-md-6 col-lg-5 col-xl-5">
                <h4>Más sobre SonoClean &reg;</h4>
                <hr class="d-inline-block mx-auto">
                <p class="footer__links__p">Con la creación de SonoClean <span class="green">&reg;</span> queremos acercar cada solución de limpieza a las solicitudes cada
                    vez más específicas de nuestros clientes, y ofrecer una solución concreta a sus exigencias
                    específicas de lavado y tratamiento de piezas. Para ello, disponemos de equipos con tecnología puntera,
                    producidos por nosotros mismos (Sonowat) o por el grupo Surface Alliance (Hemo & LPW). Contamos con la infraestructura y con el con el 'know how' de un equipo técnico con formación especializada y con una dilatada expericencia.
                </p>
                
            </div>

            <div class="col-md-6 col-lg-4 col-xl-4">

                <h4>Servicios de limpieza</h4>
                <hr class="d-inline-block">
                <ul class="footer__links__ul">
                    <li><a routerLink="/servicios-de-limpieza/disolventes-aiii" title="limpieza de piezas base disolventes aiii"><span>Base disolventes AIII</span></a></li>
                    <li><a routerLink="/servicios-de-limpieza/base-acuosa-camara" title="limpieza de piezas particulas base acuosa en camara"><span>Base acuosa en cámara</span></a></li>
                    <li><a routerLink="/servicios-de-limpieza/hibrida-disolvententes-aiii-detergentes" title="limpieza de piezas con disolventes y agua con detergentes"><span>Proceso combinado</span></a></li>
                    <li><a routerLink="/servicios-de-limpieza/ultrasonidos" title="servicio de limpieza con ultrasonidos"><span>Inmersión con ultrasonidos</span></a></li>
                    <li><a routerLink="/servicios-perifericos" title="servicios perifericos y auxiliares sonoclean"><span>Procesos complementatrios y periféricos</span></a></li>
                </ul>

                <h4 class="pt-medium">Máquinas de limpieza<br> con Ultrasonidos</h4>
                <hr class="d-inline-block">
                <ul class="footer__links__ul">
                    <li><a href="https://www.sonowat.com/" target="_blank" title="maquinas limpieza ultrasonidos"><span>sonowat.com</span></a></li>
                </ul>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3">


                <h4>Contacto</h4>
                <hr class="d-inline-block">
                <ul class="footer__links__adress-ul">
                    <li>
                        <i class="fa fa-home mr-2"></i> Pol. Ind. Santiga<br>
                        C/ Argenters 11-13, nave 8<br>
                        08130 Sta. Perpètua de la Mogoda, Barcelona.
                    </li>
                    <li><i class="fa fa-envelope mr-2"></i> <a href="mailto:contact@sonoclean.eu" target="_blank">contact@sonoclean.eu</a></li>
                    <li><i class="fa fa-phone mr-2"></i> <a href="tel:+34937294783">+ 34 93 400 84 00</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer__bottom">© 2021 <a routerLink="/" title="SonoClean Servicios de limpieza de piezas">SonoClean</a> <span class="green ml-1">&reg;</span>
    </div>

</footer>