import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-links',
  templateUrl: './services-links.component.html',
  styleUrls: ['./services-links.component.scss']
})
export class ServicesLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
