<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH ULTRASONIC CLEANERS'</span>
        <h1 class="slide-top-second">Servicio de limpieza de piezas<br class="d-none d-lg-block"> por inmersión con
            ultrasonidos y detergentes</h1>
        <hr class="introHr slide-left-right">
        <p class="slide-top-third">Lavado en baño con agua a temperatura y detergentes para recuperación, exterior e
            interior, de piezas metálicas</p>
    </div>
</header>
<section class="container-fluid ultrasonidos no-gutters-own bg-ecru">
    <div class="container ultrasonidos__container mx-auto">
        <div class="row">
            <nav class="col-12 breadcrumb-lg-intro">
                <ol class="breadcrumb-lg-intro__ol">
                    <li class="breadcrumb-item"><a routerLink="/"
                            title="servicio de limpieza de piezas sonoclean">Volver a la home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/servicios-de-limpieza"
                            title="todos nuestros servicios de limpieza de piezas"><span>todos</span></a></li>
                    <li class="breadcrumb-item active" aria-current="page">limpieza con ultrasonidos</li>
                </ol>
            </nav>
        </div>
        <div class="row ultrasonidos__container__row">
            <div class="col-12 col-lg-6 ultrasonidos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/altos-niveles-limpieza-exterior-interior.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body ultrasonidos__container__row__col__text">
                        <h2><span class="light">Procesos de limpieza con ultrasonidos </span><br>por
                            inmersión en baño con agua y detergentes</h2>
                        <hr>

                        <p>Los procesos de limpieza por inmersión en baño/s con agua y detergentes (base ácida,
                            base alcalina o decapantes) con los que trabajan nuestras máquinas tipo
                            <span class="medium">'Sonowat DTG US / Cleaning'</span>, son especialmente indicados para
                            la eliminación de restos tanto orgánicos <span class="medium">(aceites,
                                grasas, sarros, ceras, o petróleos
                                o ceras)</span>, como inorgánicos <span class="medium">(sales,
                                pinturas, huellas, emulsiones)</span>.</p>

                        <p>A diferencia de los procesos con base disolvente, base acuosa e híbridos, más enfocados a
                            procesos de producción de pieza nueva, la limpieza por inmersión en baño con ultrasonidos es
                            más bien indicada para la recuperación de piezas con suciedades incrustadas en su exterior e
                            interior, tanto por uso y desgaste como en procesos industriales que precisan mantenimiento.
                            El espectro de posibles aplicaciones finales y sectores de aplicación de este proceso es muy
                            amplio.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 ultrasonidos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/maquinas-tecnologia-puntera-alemana-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body ultrasonidos__container__row__col__text">
                        <h2><span class="light">Infraestructura, amplia experiencia</span><br> y
                            tecnología 'Hi Tech' de Sonowat con equipos de alta calidad y versátiles</h2>
                        <hr>
                        <p>En SonoClean contamos con la tecnología y el 'know how' de nuestro equipo técnico para
                            resolver
                            los casos más exigentes de limpieza, desengrase y secado de piezas.</p>
                        <p>Para ofrecer el servicio por inmersión en baño con agua y detergentes en producciones
                            medianas de piezas o para piezas XXL trabajamos con maquinaria Sonowat, de una sola etapa o
                            multietapa, según el requisito de limpieza. Sonowat lleva tiene más de 40 años de
                            experiencia en la fabricación de equipos de limpieza con ultrasonidos y comparte tecnología
                            y equipo técnico con uno de los grupos más especializados, con más concimientos y más
                            punteros de Europa en este sector.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="container-fluid ultrasonidos no-gutters-own">
    <div class="container ultrasonidos__container mx-auto">
        <div class="row ultrasonidos__container__row">
            <div class="col-12 ultrasonidos__container__row__col">
                <h3><span class="light">Esquema de proceso y detalle de nuestros</span><br> equipos de limpieza con
                    ultrasonidos 'DTG US Cleaning'
                </h3>
                <hr>
                <div class="ultrasonidos__container__row__col__processGrid">

                    <figure>
                        <img src="./assets/img/icons/solvacs/seleccion-programa-hemo-sonowat-blue.svg"
                            alt="seleccion programa limpieza particulas" width="100%" height="auto">
                        <figcaption>Selección programa</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/carga-automatica-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Carga automática</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/ultrasonidos-producto-acido-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>US prelavado</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/ultrasonidos-producto-acido-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>US producto 1</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/ultrasonidos-producto-acido-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>US producto 2</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/fases-enjuague-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Aclarado caliente</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/chorro-agua-presion-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Agua a presión</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/fases-enjuague-agua-desionizada-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Agua desionizada</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/fases-secado-aire-caliente-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Secado caliente</figcaption>
                    </figure>

                    <figure>
                        <img src="./assets/img/icons/multietapa/descarga-automatica-multietapa-sonowat.svg"
                            alt="carga automatica máquinas multietapa" width="100%">
                        <figcaption>Descarga automática</figcaption>
                    </figure>
                </div>
            </div>


            <div
                class="col-lg-5 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large d-flex align-items-center">
                <p class="description">El proceso de limpieza con ultrasonidos por inmersión en baños con agua y
                    detergentes es una configuración modular resultado de un estudio previo por parte de nuestro equipo
                    técnico. Al contar con gran variedad de equipos con distintas frecuencuas (kHz), y densidades de
                    potencia (Weff.), estudiamos cada caso según tamaño de las piezas, tipo y cantidad de residuos
                    acumulados en su interior y esterior, geometría de las mismas y requisitos de limpieza para
                    configurar el proceso adecuado en cada caso. Desde producciones elevadas a unidades únicas con
                    requisitos especiales o XXL.</p>
            </div>
            <figure class="col-12 col-lg-7 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large">
                <img src="./assets/img/maquinas-limpieza-ultrasonidos/maquina-limpieza-ultrasonidos-sonowat-filtros-de-cocina.jpg"
                    alt="maquina de limpieza con ultrasonidos para filtros" width="100%" height="auto">
                <figcaption>Detalle de equipo DTG 'US Cleaning' TT Industrial Sonowat para recuperación de filtros
                </figcaption>
            </figure>


            <div class="col-12">
                <h3><span class="light">Detalle de proceso</span><br> de limpieza con ultrasonidos XXL
                </h3>
                <hr>
            </div>

            <div class="col-12 col-lg-3 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large">
                <img src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-antes.jpg"
                    alt="limpieza con ultrasonidos piezas xxl sonoclean" width="100%" height="auto">
            </div>
            <figure
                class="col-12 col-lg-3 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large d-none d-lg-block">
                <img src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-inmersion.jpg"
                    alt="maquina de limpieza con ultrasonidos para filtros" width="100%" height="auto">
            </figure>
            <figure
                class="col-12 col-lg-3 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large d-none d-lg-block">
                <img src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-durante.jpg"
                    alt="maquina de limpieza con ultrasonidos para filtros" width="100%" height="auto">
            </figure>
            <figure class="col-12 col-lg-3 ml-lg-auto ultrasonidos__container__row__col-machineImage mt-large">
                <img src="./assets/img/aplicaciones-limpieza/limpieza-con-ultrasonidos-piezas-xxl-turbina-despues.jpg"
                    alt="maquina de limpieza con ultrasonidos para filtros" width="100%" height="auto">
            </figure>
        </div>
    </div>
</section>
<section class="container-fluid ultrasonidos no-gutters-own bg-ecru">
    <div class="container ultrasonidos__container mx-auto">
        <div class="row ultrasonidos__container__row">

            <div class="col-12 col-lg-6 ultrasonidos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon" src="./assets/img/icons/info-sonoclean.svg"
                        alt="altos niveles de limpieza y desengrase exterior">
                    <div class="media-body ultrasonidos__container__row__col__text">
                        <h3 class="green">¿En qué casos es el más indicado?</h3>
                        <hr>
                        <p>Como menciomamos en los puntos anteriores es un proceso especialmente indicado para recuperación de piezas, en procesos de producción o por uso y desgaste, en los que las piezas acumulan suciedades como <span
                                class="medium">aceites,
                                grasas, pinturas, carbonillas, sarro, lacas, desmoldeantes, pastas de pulir</span> u otro tipo de suciedades en piezas dereivadas de actividades industriales de sectores como el mecánico generalista, el automovilístico, el aeronáutico, el médico dental, el electrónico, el alimentario, el químico o para dar soporte a limpiezas de base disolvente.</p>
                        <h4 class="common__subtitle">Materiales</h4>
                        <ul class="common__list">
                            <li>Hierro</li>
                            <li>Aluminio</li>
                            <li>Latón</li>
                            <li>Cobre</li>
                            <li>Acero inoxidable</li>
                            <li>Níquel</li>
                            <li>Zinc</li>
                            <li>Plomo</li>
                            <li>Resinas rígidas</li>
                            <li>Plásticos rígidos</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 ultrasonidos__container__row__col-infoService">
                <div class="media">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integra-servicio-limpieza-sonoclean-2.svg"
                        alt="logistica integral del servicio">
                    <div class="media-body ultrasonidos__container__row__col__text">
                        <h3 class="green">Opciones logísticas del serivcio</h3>
                        <hr>

                        <ul>
                            <li>Lavado de piezas a granel</li>
                            <li>Lavado de piezas colocadas en cesta</li>
                            <li>Lavado de piezas colocadas en utillaje</li>
                            <li>Lavado de piezas XXL</li>
                            <li>Protecciones anicorrosivas y antioxidantes</li>
                            <li>Recuento de piezas pre y post lavado</li>
                        </ul>
                    </div>
                </div>
                <div class="media mt-medium">
                    <img class="align-self-start paragraph__icon"
                        src="./assets/img/icons/logistica-integral-servicio/control-de-particulas.svg"
                        alt="controles y certificaciones de lavado">
                    <div class="media-body ultrasonidos__container__row__col__text">

                        <h3 class="green">Controles y Certificaciones de lavado</h3>
                        <hr>
                        <ul>
                            <li>Controles de granulometría</li>
                            <li>Controles de tensión superficial</li>
                            <li>Certificaciones de lavado y documentaciones de procesos</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-call-to-action></app-call-to-action>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
        <div class="row generic-section__container__row">
            <div class="col-12 generic-section__container__row__col">
                <h2 class="w-100"><span class="light">Vea todos los servicios </span><br>de limpieza y desengrase de
                    piezas SonoClean</h2>
                <hr>
            </div>
        </div>
    </div>
</section>
<app-services-links></app-services-links>