<header class="bg-intro-large">
    <div class="container no-gutters">
        <span class="slogan slide-top-first d-none d-md-block" lang="en">'HI TECH CLEANERS & DEGREASERS'</span>
        <h1 class="slide-top-second"><span class="light">¿</span>Cuáles son las aplicaciones<br
                class="d-none d-lg-block"> de limpieza de nuestros procesos<span class="light">?</span></h1>
        <hr class="introHr slide-left-right">
        <h3 class="slide-top-third">Disolventes clorados AIII, hidrocarburos AIII, base acusoa, procesos híbridos,<br
                class="d-none d-lg-block"> inmersión en agua y detergentes con ultrasonidos, decapantes con
            ultrasonidos, etc.</h3>
    </div>
</header>
<section class="container-fluid generic-section no-gutters-own">
    <div class="container generic-section__container mx-auto">
        <div class="row generic-section__container__row">
            <div class="col-12 col-lg-9 w-lg-100 generic-section__container__row__col">
                <h2 class="w-100"><span class="light">¿Cómo sabemos cuando un proceso </span><br>de lavado es el
                    indicado para nuestras piezas?</h2>
                <hr>
                <p>Esta guía gráfica nos indica el proceso de lavado idóneo según tipo, material o cantidad de residuo y
                    geometría de las piezas. <span class="medium">Base acuosa, base disolvente</span> o <span class="medium">proceso híbrido.</span></p>
                <p>En SonoClean disponemos de tecnología puntera y de los equipos necesarios abarcar todas las
                    casuísticas.</p>
            </div>
        </div>
    </div>
</section>
<main class="container-fluid no-gutters-own aplicaciones">
    <div class="container aplicaciones__container mx-auto">
        <div class="row aplicaciones__container__row">
            <figure class="col-12 col-lg-9 w-lg-100 aplicaciones__container__row__col">
                <img src="./assets/img/aplicaciones-limpieza/grafico-aplicaciones-limpieza-01.svg"
                    alt="desengrase piezas laton sector estampacion" width="100%" height="auto" />
                <figcaption class="aplicaciones__container__row__col__figcaptionTop">
                    <h3>Base acuosa</h3>
                    <h4 class="d-none d-md-block">Agua con ultrasonidos y<br> turbulencia + detergentes</h4>
                </figcaption>
                <figcaption class="aplicaciones__container__row__col__figcaptionMiddle">
                    <h3>Limpieza híbrida</h3>
                    <h4 class="d-none d-md-block">Disolventes AIII +<br> agua con detergentes</h4>
                </figcaption>
                <figcaption class="aplicaciones__container__row__col__figcaptionBottom">
                    <h3>Base disolvente</h3>
                    <h4 class="d-none d-md-block">Hidrocarburos AIII o<br> Alcoholes Modificados AIII</h4>
                </figcaption>
            </figure>
            <div class="col-12 col-lg-9 w-lg-100 aplicaciones__container__row__col-legend">
                <ul>
                    <li>Alcoholes Modificados AIII <br class="d-blog d-md-none">
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-acuosa.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                    </li>
                    <li>Combinación Hidrocarburos<br> y Alcoholes Modificados AIII<br class="d-blog d-md-none">
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                    </li>
                    <li>Agua con Detergentes<br class="d-blog d-md-none">
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-acuosa.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-acuosa.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                    </li>
                    <li>Hidrocarburos No Clorados<br class="d-blog d-md-none">
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-hibrida.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                    </li>
                    <li>Hidrocarburos Clorados<br class="d-blog d-md-none">
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                        <img src="./assets/img/icons/particula-limpieza-base-disolvente.svg"
                            alt="eliminacion particulas con limpieza base disolvente" width="26px" height="26px"
                            class="ml-2" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</main>
<app-call-to-action></app-call-to-action>