<section class="container-fluid no-gutters-own keypoints">
    <div class="container keypoints__container">
        <h2><span class="light">Alta capacidad tecnológica y de </span> <br>gestión en un servicio llaves en mano</h2>
        <hr>
        <div class="keypoints__container__logos-grid">

            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/spot-free-white.svg" alt="produccion integramente europea" width="100%">
                <figcaption class="flexKPInsideItem">Tecnología 'Solvacs' en lavado y secado en cámara al vacío con disolventes AIII</figcaption>
            </figure>

            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/alta-limpieza-producciones-elevadas-white.svg"
                    alt="altos niveles de limpieza exterior e interior" width="100%">
                <figcaption class="flexKPInsideItem">Limpieza con ultrasonidos 'Hi-Tech' EXT-INT para gran diversidad de piezas</figcaption>
            </figure>

            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/colocacion-en-utillaje-white.svg" alt="capacidad de trabajo 24 horas"
                    width="100%">
                <figcaption class="flexKPInsideItem">Procesos de limpieza con pieza a granel y pieza colocada</figcaption>
            </figure>


            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/recuento-de-piezas-white.svg" alt="capacidad de trabajo 24 horas"
                    width="100%">
                <figcaption class="flexKPInsideItem">Software de recuento de piezas pre y post proceso de lavado</figcaption>
            </figure>

            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/control-de-particulas-white.svg" alt="ultrasonidos alta calidad" width="100%">
                <figcaption class="flexKPInsideItem">Controles de partículas y suciedades con certificaciones post lavado</figcaption>
            </figure>

            

            <figure class="keypoints__container__logos-grid__item">
                <img src="./assets/img/icons/logistica-integral-servicio/envasado-bolsas-vci-white.svg" alt="componentes de primeras marcas"
                    width="100%">
                <figcaption class="flexKPInsideItem">Envasado al vacío de las piezas o en bolsas VCI y gestión del envío</figcaption>
            </figure>

        </div>
    </div>
</section>

