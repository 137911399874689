<header class="bg-intro-medium">
    <div class="container no-gutters">
        <h1 class="slide-top-first">Aviso Legal</h1>
        <hr class="introHr slide-left-right">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb slide-top-second">
                <li class="breadcrumb-item"><a routerLink="/" title="servicio de limpieza de piezas">Volver a la home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Aviso Legal</li>
            </ol>
        </nav>
    </div>
</header>